
import Vue from "vue"
import { Context } from "@nuxt/types"
import { getPage } from "~/services/public/Pages"
import { TestLabService } from "~/services/public/TestLab"

interface ComponentData {
  page: any
}

export default Vue.extend({
  name: "SlugPage",

  middleware: ["preview"],

  async asyncData({ params, error, payload, $config }: Context): Promise<ComponentData | void> {
    try {
      if (payload) {
        return { page: payload }
      }

      const response = await getPage($config, params.slug + $config.appSuffix)

      if (response.loading === false && response.data) {
        if (response.data.contentNode) {
          return {
            page: response.data.contentNode,
          }
        } else {
          throw new Error("Page not found - data error")
        }
      }
    } catch (err) {
      // error({ statusCode: 404, message: 'Page not found' })
      throw new Error("Page not found - response error")
    }
  },

  data(): ComponentData {
    return {
      page: null
    }
  },

  mounted(): void {
    this.initializeTestLab()
  },

  methods: {
    async initializeTestLab(): Promise<void> {
      const testLabService = new TestLabService(
        this.$axios,
        this.$config,
        this.$snowplow,
        this.$store
      )
      // adds experiment context to vuex store if page has any experiments.
      await testLabService.handleExperiments(this.page.blocks)
      this.$snowplow.trackViewForPage(this.page)
    }
  }
})
